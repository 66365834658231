import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Split Jerk 5×2\\@80% 1RM`}</p>
    <p>{`Band Pull Aparts 5×10`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`100/80 Calorie Ski Erg`}</p>
    <p>{`80-S2OH (75/55)(RX+ 95/65)`}</p>
    <p>{`60-Pullups`}</p>
    <p>{`40-GHD Situps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      